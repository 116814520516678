import React, {useEffect, useState} from 'react';
import Image from "next/legacy/image";
import Link from "next/link";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {useRouter} from 'next/router';
import {toast, ToastContainer} from 'react-toastify';
import {useTranslation} from 'next-i18next';

// Loader
import dynamic from "next/dynamic";
// Images
import logo_light from '../../public/logo_light.png';
import logo_dark from '../../public/logo_dark.png';
import logo_mobile from '../../public/upyo-logo-m.svg';
import logo_mobile_dark from '../../public/upyo-logo-m_dark.svg';
import google_play from '../../public/upyo__app.svg';
import {capitalize} from '../../helper/utilityHelper'

//icons
import FooterTwitterIcon from '../icons/footerTwitterIcon';
import FooterInstagramIcon from '../icons/footerInstagramIcon';
import FooterTelegramIcon from '../icons/footerTelegramIcon';
import FooterYoutubeIcon from '../icons/footerYoutubeIcon';
import FooterFacebookIcon from '../icons/footerFacebookIcon';
import ReadMoreIcon from '../icons/readMoreIcon';

// API helper
import {apiHelperBlockchain} from '../../helper/apiHelper';
// styles
import 'react-toastify/dist/ReactToastify.css';
import styles from "./Footer.module.scss";
import DidFooter from "./didFooter";

const OnlyLoader = dynamic(() => import('../common/loader/onlyLoader'));

const Footer = (props) => {

    const { t } = useTranslation('common');
    const router = useRouter();
    const [selectedOption, setSelectedOption] = useState({ value: 'English', label: 'English' });

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailLoader, setEmailLoader] = useState(false);
    const [ifId, setIfId] = useState(false);

    useEffect(() => {
        if (props.locale === 'ar') {
            setSelectedOption({ value: 'Arabic', label: 'Arabic' })
        }
        if(window.location.href.includes("/id")){
            setIfId(true);
        }
    },[])



    useEffect(() => {
        router.push(
            router.asPath,
            undefined,
            {locale: selectedOption.value === 'Arabic' ? 'ar' : 'en'})
    },[selectedOption])

    const handleChangeEmail = (e) => {
        let em = e.target.value;
        let err = "";
        if (em === "") {
            err = this.props.t('common:error_email');
        }
        else if (!this.validateEmail(em)) {
            err = this.props.t('common:error_valid_email');
        }
        setEmail(em);
        setEmailError(err);
    }

    const validateEmail = (email) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    const saveSubscription = (e) => {
        e.preventDefault();
        if (email === "") {
            setEmailError(t('common:error_email'));
        } else if (!validateEmail(email)) {
            setEmailError(t('common:error_valid_email'));
        } else {
            setEmailLoader(true);
            let referUrl = process.env.hostBaseUrl + '/' + this.props.currLocale + router.asPath;

            let pData = {
                email,
                pageId: props.pageId ? props.pageId : '',
                pageName: props.pageName ? props.pageName : '',
                referUrl: referUrl ? referUrl : '',
                action: 'arrow'
            };

            // save-subscribe
            apiHelperBlockchain('main-site-subscribe', 'POST', pData).then(res => {
                if (res.data.status) {
                    toast(res.data.message);
                }
                else {
                    setEmailError(res.data.message);
                }
                setEmailLoader(false);
                setEmail("");
            }).catch(error => console.error(`Error: ${error}`));
        }
    }

    const currYear = new Date().getFullYear();
    const { currLocale, categoryList } = props;
    const linkHref = ifId ? "/id" : "/did"
    const validPathNames = ["/[did]", "/[did]/certs", "/[did]/web", "/[did]/wallet", "/[did]/about-us", "/[did]/use-cases", "/[did]/how-it-works", "/[did]/identity-access-management", "/[did]/healthcare", "/[did]/metaverse", "/[did]/education" ]
    const validDashBoardPathNames = [ "/[did]/dashboard", "/[did]/credentials", "/[did]/issue", "/[did]/template", "/[did]/verifiable", "/[did]/verification", "/[did]/create-template", "/[did]/verification-template", "/[did]/verification-history", "/[did]/verify-credentials", "/[did]/login"]

    if([...validPathNames, ...validDashBoardPathNames].includes(router.pathname)){
        return (
            <DidFooter currLocale={currLocale}
                       validDashBoardPathNames={validDashBoardPathNames}
                       linkHref={linkHref} email={email}
                       handleChangeEmail={handleChangeEmail}
                       emailError={emailError}
                       emailLoader={emailLoader}
                       currYear={currYear} />
        )
    }

    return (
        <>
            <footer className={styles.footer}>
                <Container>
                    <div className={`d-md-flex ${styles.footer__top} ${styles.border_bottom}`}>
                        <Col md={6}>
                            <div className={styles.footer__details}>{t('common:join_com')}</div>
                            <ul className={`d-flex ${styles.footer__connection_list}`}>
                                <li>
                                    <Link href="https://twitter.com/upyocom"
                                          title="twitter" target='_blank' rel="noopener">
                                        <FooterTwitterIcon />
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.instagram.com/upyonft"
                                          title="instagram" target='_blank' rel="noopener">
                                        <FooterInstagramIcon />
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://t.me/UPYOnft"
                                          title="Telegram" target='_blank' rel="noopener">
                                        <FooterTelegramIcon />
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.youtube.com/channel/UCCg3H6ZX1YRKgorLkGMuz-w"
                                          title="youtube" target='_blank' rel="noopener">
                                        <FooterYoutubeIcon />
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://web.facebook.com/UPYOcom"
                                          title="facebook" target='_blank' rel="noopener">
                                        <FooterFacebookIcon />
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={6} className="d-flex flex-column align-items-center align-items-md-end">
                            <div className={styles.right}>
                                <div className={styles.footer__details}>{t('common:download_the_app')}</div>
                                <div className={`d-flex ${styles.footer__top}`}>
                                    <Link href="https://play.google.com/store/apps/details?id=com.upyo.nft&pli=1"
                                          title="Google Play" rel="noopener" target="_blank" className='d-flex'>
                                        <Image src={google_play} alt="google_play"
                                               width={161} height={50} />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </div>
                    <div className={styles.footer__center}>
                        <div className="row">
                            <Col md={6} lg={4} className={styles.footerWidget}>
                                <Link locale={currLocale} href="/nft"
                                      className={`d-flex logo_style ${styles.footer__logo}`}>
                                    <span className={`light ${styles.dflex}`}>
                                        <span className="d-none d-sm-block">
                                            <Image src={logo_light} alt="UpYo NFT"
                                                   width={102} height={33}
                                                   layout="fixed" />
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <Image src={logo_mobile} alt="UpYo NFT"
                                                   width={40} height={40}
                                                   layout="fixed" />
                                        </span>
                                    </span>
                                    <span className={`dark ${styles.dflex}`}>
                                        <span className="d-none d-sm-block">
                                            <Image src={logo_dark} alt="UpYo NFT"
                                                   width={102} height={33}
                                                   layout="fixed" />
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <Image src={logo_mobile_dark} alt="UpYo NFT"
                                                   width={40} height={40}
                                                   layout="fixed" />
                                        </span>
                                    </span>
                                </Link>
                                <div className={styles.footer__info}>
                                    {t('common:footer_desc')}
                                </div>
                                <div className={styles.footer__theme}>
                                    <div className={`text-start ${styles.footer__details}`}>
                                        {t('common:upyo_update_email_lbl')}
                                    </div>
                                    <form className={styles.subscription} onSubmit={saveSubscription}>
                                        <input
                                            className={`form-control ${styles.subscription__input}`}
                                            type="text"
                                            value={email}
                                            onChange={handleChangeEmail}
                                            placeholder={t('common:enter_email')}
                                        />
                                        <button type="submit" className={`p-0 ${styles.subscription__btn}`}>
                                            {emailLoader ?
                                                <OnlyLoader />
                                                : <ReadMoreIcon className={styles.colors} />}
                                        </button>
                                    </form>

                                    {emailError && <span className='valmsg'>{emailError}</span>}
                                </div>
                            </Col>
                            <Col md={6} lg={4} className={styles.footerWidget}>
                                <div className={styles.footer__head}>
                                    {t('common:marketplace')}
                                </div>
                                <div className={styles.footer__menu}>
                                    <ul>
                                        <li>
                                            <Link locale={currLocale} href={"/nft/category"}
                                                  className={styles.footer__link}>
                                                {t('common:all_items')}
                                            </Link>
                                        </li>
                                        {categoryList && categoryList.length > 0 &&
                                            categoryList.map((item) => {
                                                return (
                                                    <li key={item.page_name}>
                                                        <Link locale={currLocale}
                                                              href={`/nft/category/${encodeURI(item.page_name)}`}
                                                              className={styles.footer__link}>
                                                                {currLocale === "en" ? capitalize(item.category_name) : item.category_name_arabic}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </Col>
                            <Col md={6} lg={2} className={styles.footerWidget}>
                                <div className={styles.footer__head}>
                                    {t('common:resources')}
                                </div>
                                <div className={styles.footer__menu}>
                                    <ul>
                                        <li>
                                            <Link href={`${process.env.hostBaseUrl}${currLocale ? '/'+currLocale : '/en'}/blog`}
                                               className={styles.footer__link}>
                                                <span>{t('common:blog')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href={`/news`}
                                                  className={styles.footer__link}>
                                                <span>{t('common:news')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href={`/events`}
                                                  className={styles.footer__link}>
                                                <span>{t('common:events_nav')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href="/nft/contact"
                                                  className={styles.footer__link}>
                                                <span>{t('common:help_center')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`https://help.upyo.com/${currLocale}`}
                                                  className={styles.footer__link}>
                                                <span>{t('common:help_link')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href={`/sitemap`}
                                                  className={styles.footer__link}>
                                                <span>{t('common:sitemap')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href="https://airtable.com/shrIb0wREBDSHOHzA"
                                                  className={styles.footer__link}>
                                                <span>{t('common:Celebrities')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href="https://airtable.com/shrBlTqqGPm7upGVg"
                                                  className={styles.footer__link}>
                                                <span>{t('common:Projects')}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={6} lg={2} className={styles.footerWidget}>
                                <div className={styles.footer__head}>
                                    {t('common:company')}
                                </div>
                                <div className={styles.footer__menu}>
                                    <ul>
                                        <li>
                                            <Link locale={currLocale} href="/nft/about"
                                                  className={styles.footer__link}>
                                                <span>{t('common:about')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="https://airtable.com/shrQTtJAcEJMxLbMW"
                                                  className={styles.footer__link}>
                                                <span>{t('common:partner')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link locale={currLocale} href="/nft/app"
                                                  className={styles.footer__link}>
                                                <span>{t('common:nft_app')}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className={styles.footer__foot}>
                        <div className={styles.footer__copyright}>
                            {t('common:copyright', { year: currYear })}
                        </div>
                        <div className={styles.footer__note_link}>
                            <Link locale={currLocale} href="/nft/terms"
                                  className={styles.footer__link}>
                                {t('common:terms')}
                            </Link>
                            <Link locale={currLocale} href="/nft/privacy"
                                  className={styles.footer__link}>
                                {t('common:privacy_policy')}
                            </Link>
                        </div>
                    </div>
                </Container>
            </footer>
            <ToastContainer />
        </>
    )
}
export default Footer;