import React from 'react';
import styles from "./Footer.module.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Link from "next/link";
import FooterTwitterIcon from "../icons/footerTwitterIcon";
import FooterInstagramIcon from "../icons/footerInstagramIcon";
import FooterTelegramIcon from "../icons/footerTelegramIcon";
import FooterYoutubeIcon from "../icons/footerYoutubeIcon";
import FooterFacebookIcon from "../icons/footerFacebookIcon";
import Image from "next/legacy/image";
import logo_light from "../../public/logo_light.png";
import logo_dark from "../../public/logo_dark.png";
import ReadMoreIcon from "../icons/readMoreIcon";
import {useRouter} from "next/router";
import {useTranslation} from "next-i18next";
import dynamic from "next/dynamic";
const OnlyLoader = dynamic(() => import('../common/loader/onlyLoader'));


function DidFooter(props) {

    const {
        email, handleChangeEmail, emailLoader, emailError, currLocale,
        validDashBoardPathNames, linkHref, currYear
    } = props;
    const router = useRouter();
    const { t } = useTranslation('common');

    if(validDashBoardPathNames.includes(router.pathname)){
        return <></>
    }

    return (
        <footer className={`${styles.footer} ${styles.didFooter}`}>
            <Container>
                <div className={`d-md-flex ${styles.footer__top} ${styles.border_bottom}`}>
                    <Col md={6}>
                        <div className={styles.footer__details}>{t('common:join_com')}</div>
                        <ul className={`d-flex ${styles.footer__connection_list}`}>
                            <li>
                                <Link href="https://twitter.com/upyocom"
                                      title="twitter" target='_blank' rel="noopener">
                                        <FooterTwitterIcon />
                                </Link>
                            </li>
                            <li>
                                <Link href="https://www.instagram.com/upyonft"
                                      title="instagram" target='_blank' rel="noopener">
                                        <FooterInstagramIcon />
                                </Link>
                            </li>
                            <li>
                                <Link href="https://t.me/UPYOnft"
                                      title="Telegram" target='_blank' rel="noopener">
                                        <FooterTelegramIcon />
                                </Link>
                            </li>
                            <li>
                                <Link href="https://www.youtube.com/channel/UCCg3H6ZX1YRKgorLkGMuz-w"
                                      title="youtube" target='_blank' rel="noopener">
                                        <FooterYoutubeIcon />
                                </Link>
                            </li>
                            <li>
                                <Link href="https://web.facebook.com/UPYOcom"
                                      title="facebook" target='_blank' rel="noopener">
                                        <FooterFacebookIcon />
                                </Link>
                            </li>
                        </ul>
                    </Col>
                </div>
                <div className={styles.footer__center}>
                    <div className="row">
                        <Col md={6} lg={2} className={styles.footerWidget}>
                            <Link locale={currLocale} href={linkHref}
                                  className={`d-flex logo_style ${styles.footer__logo}`}>
                                <span className={`light ${styles.dflex}`}>
                                    <span>
                                        <Image src={logo_light} alt="UpYo NFT" width={102} height={33} layout="fixed" />
                                    </span>
                                </span>
                                <span className={`dark ${styles.dflex}`}>
                                    <span>
                                        <Image src={logo_dark} alt="UpYo NFT" width={102} height={33} layout="fixed" />
                                    </span>
                                </span>
                            </Link>
                            <div className={styles.footer__info}>
                                UPYO ID helps you protect your digital documents from fraud and makes them verifiable in seconds.
                            </div>
                            <div className={styles.footer__theme}>
                                <div className={`text-start ${styles.footer__details}`}>{t('common:upyo_update_email_lbl')}</div>
                                <form className={styles.subscription} onSubmit={saveSubscription}>
                                    <input
                                        className={`form-control ${styles.subscription__input}`}
                                        type="text"
                                        value={email}
                                        onChange={handleChangeEmail}
                                        placeholder={t('common:enter_email')}
                                    />
                                    <button type="submit" className={`p-0 ${styles.subscription__btn}`}>
                                        {emailLoader ? <OnlyLoader />
                                            :
                                            <ReadMoreIcon className={styles.colors} />
                                        }
                                    </button>
                                </form>

                                {emailError && <span className='valmsg'>{emailError}</span>}
                            </div>
                        </Col>
                        <Col md={6} lg={2} className={styles.footerWidget}>
                            <div className={styles.footer__head}>
                                Solutions
                            </div>
                            <div className={styles.footer__menu}>
                                <ul>
                                    <li>
                                        <Link locale={currLocale} href={`${linkHref}/certs`}
                                              className={styles.footer__link}>
                                            Certs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link locale={currLocale} href={`${linkHref}/web`}
                                              className={styles.footer__link}>
                                            Web3 ID
                                        </Link>
                                    </li>
                                    <li>
                                        <Link locale={currLocale} href={`${linkHref}/wallet`}
                                              className={styles.footer__link}>
                                            Wallet
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={2} className={styles.footerWidget}>
                            <div className={styles.footer__head}>
                                Industries
                            </div>
                            <div className={styles.footer__menu}>
                                <ul>
                                    <li>
                                        <Link href={`${linkHref}/education`}
                                              className={styles.footer__link}>
                                            Education
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`${linkHref}/metaverse`}
                                              className={styles.footer__link}>
                                            Metaverse
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`${linkHref}/healthcare`}
                                              className={styles.footer__link}>
                                            Healthcare
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`${linkHref}/identity-access-management`}
                                              className={styles.footer__link}>
                                            Identity & Access Management
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={2} className={styles.footerWidget}>
                            <div className={styles.footer__head}>
                                Quick Links
                            </div>
                            <div className={styles.footer__menu}>
                                <ul>
                                    <li>
                                        <Link locale={currLocale} href={`${linkHref}/#AboutUs`}
                                              className={styles.footer__link}>
                                            About us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link locale={currLocale} href={"https://upyo.com/en/nft/contact"}
                                              className={styles.footer__link}>
                                            Contact Us
                                        </Link>
                                    </li>
                                    {/* <li><Link locale={currLocale} href={"/nft/category"}><a className={styles.footer__link}>Roadmap</a></Link></li>
                                                    <li><Link locale={currLocale} href={"/nft/category"}><a className={styles.footer__link}>Videos</a></Link></li>
                                                    <li><Link locale={currLocale} href={"/nft/category"}><a className={styles.footer__link}>Technology</a></Link></li> */}
                                </ul>
                            </div>
                        </Col>
                    </div>
                </div>
                <div className={styles.footer__foot}>
                    <div className={styles.footer__copyright}>
                        {t('common:copyright', { year: currYear })}
                    </div>
                    <div className={styles.footer__note_link}>
                        <Link locale={currLocale} href="https://upyo.com/en/nft/terms"
                              className={styles.footer__link}>
                            {t('common:terms')}
                        </Link>
                        <Link locale={currLocale} href="https://upyo.com/en/nft/privacy"
                              className={styles.footer__link}>
                            {t('common:privacy_policy')}
                        </Link>
                    </div>
                </div>
            </Container>
        </footer>
    )

}

export default DidFooter;