import React from 'react';

function FooterTwitterIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17371_34675)">
                <path d="M29.9425 5.71235C28.8363 6.1986 27.655 6.52984 26.4113 6.6811C27.6788 5.91735 28.6537 4.7136 29.115 3.27734C27.9263 3.9711 26.6088 4.4761 25.2063 4.75735C24.0863 3.5586 22.49 2.80859 20.7175 2.80859C17.3212 2.80859 14.5675 5.56235 14.5675 8.95485C14.5675 9.44235 14.6237 9.9111 14.7262 10.3598C9.61375 10.1186 5.0825 7.6636 2.05 3.95235C1.51625 4.85485 1.2175 5.9036 1.2175 7.0461C1.2175 9.1836 2.305 11.0623 3.9525 12.1661C2.94375 12.1336 1.995 11.8561 1.1675 11.3961V11.4723C1.1675 14.4536 3.28375 16.9398 6.1 17.5061C5.58375 17.6448 5.03875 17.7198 4.48 17.7198C4.0875 17.7198 3.71125 17.6823 3.335 17.6123C4.12375 20.0536 6.39125 21.8336 9.09 21.8836C6.99 23.5324 4.32875 24.5149 1.4625 24.5149C0.975 24.5149 0.48875 24.4861 0 24.4311C2.73625 26.1736 5.96 27.1924 9.44625 27.1924C20.7638 27.1924 26.945 17.8223 26.945 9.70985C26.945 9.4486 26.945 9.18484 26.9263 8.92235C28.1275 8.06109 29.1762 6.97235 30.0012 5.73735L29.9425 5.71235Z" fill="#55ACEE" />
            </g>
            <defs>
                <clipPath id="clip0_17371_34675">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FooterTwitterIcon;